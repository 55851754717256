<template>
  <div class="elv-chain-icon">
    <div class="elv-chain-icon-content">
      <template v-if="props.type === 'entity'">
        <img
          v-if="reportStore.entityDetail.logo"
          :src="reportStore.entityDetail.logo"
          class="elv-chain-icon-content__logo"
          :onerror="useDefaultImage"
        />
        <img
          v-else
          src="@/assets/img/reports/default-entity-logo.png"
          class="elv-chain-icon-content__logo"
          :onerror="useDefaultImage"
        />
      </template>
      <SvgIcon v-else class="elv-chain-icon-content__svg" name="menu-my-projects" width="16" height="16" />
    </div>
    <div>
      <div class="elv-project-info">
        <div class="elv-project-info-name">
          {{ currentName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectStore } from '@/stores/modules/project'
import { useReportStore } from '@/stores/modules/reports/index'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

const props = defineProps({
  type: {
    type: String,
    default: 'entity'
  }
})

const reportStore = useReportStore()
const projectStore = useProjectStore()

const currentName = computed(() => {
  if (props.type === 'entity') {
    return reportStore.entityDetail.name
  }
  if (
    props.type === 'project' &&
    (projectStore.projectDetail?.plan === 'FREE' || projectStore.projectDetail?.plan === 'BASIC')
  ) {
    return projectStore.projectEntityList[0]?.name
  }
  return projectStore.projectDetail?.name
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>

<style scoped lang="scss">
.elv-chain-icon {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;

  .elv-chain-icon-content {
    margin-right: 8px;
    align-self: flex-start;
    height: 100%;
    display: flex;
    align-items: center;

    .elv-chain-icon-content__logo {
      width: 24px;
      height: 24px;
      display: block;
      border-radius: 50px;
    }

    .elv-chain-icon-content__svg {
      fill: #b3bfce;
    }
  }

  .elv-project-info {
    display: flex;
    align-items: center;
    height: 31px;

    .elv-project-info-name {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      color: #000;
    }

    .elv-project-info-token {
      height: 21px;
      box-sizing: border-box;
      padding: 0px 8px;
      font-family: 'Unbounded Variable';
      font-weight: 400;
      font-size: 11px;
      color: $elv-color-858B92;
      border-radius: 2px;
      border: 1px solid $elv-color-858B92;
      margin-left: 8px;
      margin-top: 4px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  }
}
</style>
